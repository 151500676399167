<template>

    <div class="h-full flex flex-col" v-cloak>
        <loading-indicator v-if="!initialized" class="mt-64" />

        <div class="flex-1 flex flex-col pt-8 px-8 overflow-hidden" v-if="initialized">
            <div class="flex-shrink-0 flex justify-between items-center mb-4">
                <h2 class="text-4xl font-bold" v-if="!table_merge.active">{{$t('translations.views.pos.table.tables.title')}}</h2>
                <h2 class="text-4xl font-bold" v-else>
                    {{$t('translations.views.pos.table.tables.sentence-one')}}<span v-if="table_merge.table_from">: {{ table_merge.table_from.attributes.name }}</span> <span v-if="table_merge.table_to">{{$t('translations.views.pos.table.tables.sentence-two')}} {{ table_merge.table_to.attributes.name }}</span>
                </h2>

                <!-- This example requires Tailwind CSS v2.0+ -->
                <!-- Enabled: "bg-indigo-600", Not Enabled: "bg-gray-200" -->
                <div class="flex justify-end">
                    <div v-if="area.id == 'tablelist'" @click="setOpenTables()" class="flex justify-start gap-5 items-center mr-10">
                        <div class="text-sm">{{$t('translations.views.pos.table.tables.show-just-open-tables')}}</div>
                        <button type="button" :class="[tablelist_opentables ? 'bg-orange-600' : 'bg-gray-200']" class="bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" role="switch" aria-checked="false">
                            <span class="sr-only">{{$t('translations.views.pos.table.tables.show-just-open-tables')}}</span>
                            <span aria-hidden="true" :class="[tablelist_opentables ? 'translate-x-5' : 'translate-x-0']"  class="pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
                        </button>
                    </div>

                    <a @click="close()" class="screen-close">
                        <svg class="w-14 h-14" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </a>
                </div>
            </div>

            <div class="flex-1 grid grid-cols-5 gap-5 overflow-hidden">
                <div class="col-span-1 text-xl tables-cat-wrapper overflow-y-auto">
                    <div class="mb-5" v-if="locations.length > 0">
                        <select
                            @change="changeLocation($event)"
                            class="py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent select-table"
                        >
                            <option value="">{{$t('translations.views.pos.table.tables.select-location')}}</option>
                            <option v-for="(location, index) in locations" :value="location.id" :key="index">
                                {{ location.attributes.name }}
                            </option>
                        </select>
                    </div>
                    <div class="mb-3" v-for="(area, index) in areas" v-bind:key="index">
                        <span class="w-full inline-flex rounded-sm shadow-sm">
                            <a @click="areaSelect(area)" :class="{'selected': this.area.id == area.id}" class="button button-blur button-wide">
                                {{ area.attributes.name }}
                            </a>
                        </span>
                    </div>

                    <div class="mb-3" v-if="virtualtables.length > 0">
                        <span class="w-full inline-flex rounded-sm  shadow-sm">
                            <a @click="areaSelect('virtualtables')" :class="{'selected ': area.id == 'virtualtables'}" class="button button-blur button-wide">
                                {{$t('translations.views.pos.table.tables.virtual-tables')}}
                            </a>
                        </span>
                    </div>

                    <div class="mb-3">
                        <span class="w-full inline-flex rounded-sm  shadow-sm">
                            <a @click="areaSelect('tablelist')" :class="{'selected ': area.id == 'tablelist'}" class="button button-filled button-wide">
                                {{$t('translations.views.pos.table.tables.all-tables')}}
                            </a>
                        </span>
                    </div>

                </div>

                <div class="col-span-4 overflow-y-auto">
                    <!-- default layout -->
                    <div v-if="!area.attributes.pos_map_active" class="h-full">

                        <!-- tables -->
                        <div v-if="area.id != 'virtualtables' && area.id != 'tablelist'" class="grid grid-cols-5 gap-4 table-overview">
                            <div class="col-span-1 z-10" v-for="(table, index) in area.relationships.tables" v-bind:key="index">
                                <li :class="{'opacity-25': table.attributes.merged || !table.attributes.active_pos }" class="col-span-1 tile">

                                    <div @click="tableSelect(table)"
                                         class="">
                                        <div class="content">
                                            <div class="table-name">{{ table.attributes.name }}</div>
                                            <div class="table-amount">
                                                <div class="reset z-20">
                                                    <modal-table-reset-spending :table="table" />
                                                </div>
                                            </div>

                                            <div class="clock" v-if="table.attributes.activated_at">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="transparent" stroke="#ffcc00" viewBox="0 0 24 24" stroke-width="1.5" class="w-5 h-5">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                </svg>
                                            </div>

                                            <button
                                                v-if="isSubtableActive(table)"
                                                class="absolute right-4 bottom-2 flex items-center justify-center p-1 rounded focus:outline-none cursor-pointer"
                                                @click.stop.prevent="$refs.subtableModal.open(table.id)"
                                                style="z-index: 100;"
                                            >
                                                <svg class="w-5 h-5" width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M17.5 19.5L16 18L19 15L16 12L17.5 10.5L22 15L17.5 19.5ZM6 18V16H2C1.45 16 0.979167 15.8042 0.5875 15.4125C0.195833 15.0208 0 14.55 0 14V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H18C18.55 0 19.0208 0.195833 19.4125 0.5875C19.8042 0.979167 20 1.45 20 2V9H18V2H2V14H15V16H13V18H6ZM9 12H11V9H14V7H11V4H9V7H6V9H9V12Z" fill="white"/>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>

                                    <div @click="tableSelect(table)" :class="[table.attributes.total > 0 ? 'bg-red-500' : 'bg-green-500' ]" class="price">
                                        <currency/>{{ vueNumberFormat(table.attributes.total ? table.attributes.total : 0) }}
                                    </div>
                                </li>
                            </div>
                        </div>

                        <!-- virtualtables -->
                        <div v-if="area.id == 'virtualtables'" class="list-primary clients">
                            <!-- <div class="bg-shadow text-left overflow-auto border h-70vh" @click="setField()"> -->
                            <ul>
                                <li class="list-item" v-for="(table, index) in area.relationships.tables" v-bind:key="index">
                                    <a @click="tableSelect(table)" class="block">
                                        <div class="flex items-center gap-5 justify-between">
                                            <div class="flex justify-start text-lg font-bold">
                                                <span class="font-medium">{{ table.attributes.name }}</span>
                                                <span class="ml-3" v-if="table.attributes.name_temporary">({{ table.attributes.name_temporary}})</span>
                                                <span class="ml-3 font-normal italic" v-if="table.attributes.merged">({{$t('translations.views.pos.table.tables.table-merged')}})</span>
                                            </div>
                                            <div class="font-bold text-xl" :class="[table.attributes.total > 0 ? 'text-red-500' : 'text-green-500' ]">
                                                <currency/>{{ vueNumberFormat(table.attributes.total ? table.attributes.total : 0) }}
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <!-- tablelist -->
                        <div v-if="area.id == 'tablelist'" class="list-primary overflow-auto clients">
                            <!-- <div class="bg-shadow text-left overflow-auto border h-70vh" @click="setField()"> -->
                            <ul>
                                <li class="list-item" v-for="(table, index) in area.relationships.tables" v-bind:key="index"
                                    @click="tableSelect(table)"
                                >
                                    <a class="block">
                                        <div class="flex items-center gap-5 justify-between">
                                            <div class="flex w-2/5 justify-start items-center text-left">
                                                <span class="font-medium">{{ table.attributes.name }}

                                                </span>
                                                <span class="ml-3" v-if="table.attributes.name_temporary">({{ table.attributes.name_temporary}})</span>
                                                <span class="ml-3 font-normal italic" v-if="table.attributes.merged">({{$t('translations.views.pos.table.tables.table-merged')}})</span>
                                            </div>
                                            <div class="text-xs w-1/5 leading-5 font-medium relative">
                                                <span v-if="table.attributes.area_name" class="table-label inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-300 text-gray-800">
                                                    {{ table.attributes.area_name }}
                                                </span>
                                                <span v-if="table.attributes.virtualtable == 1" class="table-label inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-300 text-gray-800">
                                                    {{$t('translations.views.pos.table.tables.virtual')}}
                                                </span>
                                            </div>
                                            <div class="text-xs w-1/5 leading-5 font-medium">{{ table.attributes.updated_at }}</div>
                                            <div class="w-1/5 font-bold text-right text-xl" :class="[table.attributes.total > 0 ? 'text-red-500' : 'text-green-500' ]">
                                                <currency/>{{ vueNumberFormat(table.attributes.total ? table.attributes.total : 0) }}
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <!-- map layout -->
                    <div v-if="area.attributes.pos_map_active" class="h-full">
                        <div v-if="terminal.layout === 'mobiledirect' || terminal.layout === 'mobileordering'" class="overflow-y-auto map-list">
                            <div v-for="(table, index) in area.relationships.tables" v-bind:key="index"
                                 @click="tableSelect(table)"
                                 class="list-item flex justify-between mb-2"
                            >
                                <div class="">
                                    <div class="truncate">
                                        {{ table.attributes.name }}
                                    </div>
                                    <div v-if="table.attributes.name_temporary">
                                        ({{ table.attributes.name_temporary }})
                                    </div>
                                </div>
                                <div class="italic">
                                    {{ vueNumberFormat(table.attributes.total ? table.attributes.total : 0) }}
                                </div>
                            </div>
                        </div>

                        <div v-else class="map border rounded-sm relative" :style="
                        'background:url(' + area.attributes.pos_map_image + ');' +
                        'width: calc(760px * ' + terminal.map_factor + ');' +
                        'height: calc(440px * ' + terminal.map_factor + ');' +
                        'background-size: calc(760px * ' + terminal.map_factor + ') calc(440px * ' + terminal.map_factor + ') !important;'
                        ">
                            <div v-for="(table, index) in area.relationships.tables" v-bind:key="index"
                                @click="tableSelect(table)"
                                :class="{'opacity-50 cross': table.attributes.merged || !table.attributes.active_pos, 'rounded-full': table.attributes.map_shape == 'round', 'bg-red-500': table.relationships.lines.length > 0}"
                                class="bg-gray-300 table text-xs cursor-pointer absolute border text-center"
                                :style="'width:calc(' + table.attributes.map_width + ' * ' + terminal.map_factor + 'px); height:calc(' + table.attributes.map_height + ' * ' + terminal.map_factor + 'px); top: calc(' + table.attributes.map_positiontop + ' * ' + terminal.map_factor + 'px); left: calc(' + table.attributes.map_positionleft + ' * ' + terminal.map_factor + 'px)'">
                                <div class="truncate h-full flex justify-center items-center">
                                    <div>
                                        <div class="font-bold">{{ table.attributes.name }} <div v-if="table.attributes.name_temporary">({{ table.attributes.name_temporary}})</div></div>
                                        <div class="italic">{{ vueNumberFormat(table.attributes.total ? table.attributes.total : 0) }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <footer class="flex-shrink-0 button-bar pt-4 text-xl" v-if="initialized">
            <div class="flex justify-end space-x-4">
                <router-link :to="{name: 'pos.customers'}" class="button button-blur">
                    {{$t('translations.views.pos.table.tables.customers')}}
                </router-link>

                <router-link :to="{name: 'pos.tables.virtual'}" class="button button-blur">
                    {{$t('translations.views.pos.table.tables.virtual-table')}}
                </router-link>

                <a @click="tableMergeToggle()" :class="{'selected': table_merge.active}" class="button button-blur">
                    {{$t('translations.views.pos.table.tables.merge-table')}}
                </a>
                <!-- <router-link :to="{name: 'pos.tables.move'}" class="button button-blur">
                    {{$t('translations.views.pos.table.tables.move-table')}}
                </router-link> -->

                <router-link :to="{ name: 'pos.tables.move', params: { area_id: area.id }}" class="button button-blur">
                    {{$t('translations.views.pos.table.tables.move-table')}}
                </router-link>

                <modal-table-reset-spending-all @resetted="reload()" />
            </div>
        </footer>

        <modal-table-merge-confirm ref="tableMergeConfirm" @confirmed="tableMerge(true)" @canceled="tableMergeToggle()" />
        <modal-table-unavailable ref="tableUnavailable" @unmerged="refresh()" />
        <modal-table-inactive ref="tableInactive" @unmerged="refresh()" />
        <modal-table-claimed ref="tableClaimed" @unmerged="refresh()" />
    </div>

    <SubtableModal
        ref="subtableModal"
        :area="area"
        :area_id="area ? area.id : null"
    />
</template>

<style scoped>

.map {
    background: #ccc;
    background-repeat: no-repeat !important;
}
</style>

<script>
import axios from 'axios'

// mixins
import TablesHelper from '../../../mixins/tables.helper.js';

// components
import LoadingIndicator from '../../../components/LoadingIndicator'
import ModalTableResetSpending from '../../../components/ModalTableResetSpending.vue'
import ModalTableResetSpendingAll from '../../../components/ModalTableResetSpendingAll.vue'
import ModalTableMergeConfirm from '../../../components/ModalTableMergeConfirm.vue'
import ModalTableUnavailable from '../../../components/ModalTableUnavailable.vue'
import ModalTableInactive from '../../../components/ModalTableInactive.vue'
import ModalTableClaimed from '../../../components/ModalTableClaimed.vue'
import SubtableModal from '@/components/pos/SubtableModal.vue'

export default {
    name: 'Tables',
    mixins: [TablesHelper],
    components: {
        SubtableModal,
        LoadingIndicator,
        ModalTableResetSpending,
        ModalTableResetSpendingAll,
        ModalTableClaimed,
        ModalTableMergeConfirm,
        ModalTableUnavailable,
        ModalTableInactive,
    },
    provide() {
      return {
          tableSelect: this.tableSelect,
      }
    },
    data () {
        return {
            initialized: false,
            area: null,
            areas: [],
            virtualtables: [],
            locations: [],
            discounts: {},
            table_merge: {},
            tablelist_opentables: false,
            // timer: null,
        }
    },

    created () {
        this.timer = setInterval(this.tablesGetRefresh, 30000)
    },

    unmounted () {
        clearInterval(this.timer)
    },

    async mounted() {
        this.area = null
        this.areas = []
        this.virtualtables = []

        await this.tablesGet()
        await this.locationsGet();

        if (this.$route.query.opentables == true) {
            this.tablelist_opentables = true
            this.areaSelect('tablelist')
        }
    },

    methods: {

        close() {
            this.$store.dispatch('transaction/setPrestine', false)
            this.$router.push({ name: 'pos' })
        },

        reload() {
            location.reload()
        },

        refresh() {
            this.tablesGet()
        },

        async tableSelect(selectedTable) {

            const responseTable = await axios.get('/tables/' + selectedTable.id);

            if (responseTable.status !== 200) {
                this.$notify({ group: "notifications", type: 'error', title: "Fout opgetreden", text: "Oeps er ging iets fout.." }, 2000);
                return
            }

            const table = responseTable.data.data;

            // is table merged?
            if (table.relationships.merged_table) {
                this.$refs.tableUnavailable.open(table)
                return
            }

            if (!table.attributes.active_pos) {
                this.$refs.tableInactive.open(table)
                return
            }

            if (this.table_merge.active === true) {
                this.tableMergeSelect(table)

                return
            }

            if (table.attributes.active_clerk_restriction == true && table.attributes.clerk_id && table.attributes.clerk_id != this.clerk.id) {
                this.$refs.tableClaimed.open(table);
                return
            }

            this.$store.dispatch('transaction/setTable', table)
            this.$router.push({name: 'pos'})
        },

        tableMergeSelect(table) {

            if (table.attributes.active_clerk_restriction == true && table.attributes.clerk_id && table.attributes.clerk_id != this.clerk.id) {
                return this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Tafel bezet",
                    text: "Deze tafel is bezet door een andere medewerker",
                }, 2000);
            }

            if (this.table_merge.table_from == null) {
                this.table_merge.table_from = table
                return
            } else {
                this.table_merge.table_to = table

                this.tableMerge()
            }
        },

        async tableMerge(force = false) {
            await axios.post('/tables/merge', {
                    table_from_id: this.table_merge.table_from.id,
                    table_to_id: this.table_merge.table_to.id,
                    force: force,
                }).then(() => {
                    this.$notify({ group: "notifications", type: 'success', title: "Tafels zijn samengevoegd", text: this.table_merge.table_from.attributes.name + ' is samengevoegd met ' + this.table_merge.table_to.attributes.name }, 2000);

                    this.tableMergeToggle()
                    this.refresh()
                }).catch(error => {
                    if (error.response.status == 403) {
                        if (error.response.data.message == 'Table already merged') {
                            this.$notify({ group: "notifications", type: 'error', title: "Ongeldige actie", text: "Tafel reeds samengevoegd" }, 2000);

                            this.tableMergeToggle()
                        }
                        else if (error.response.data.message == 'Table has lines') {
                            this.$refs.tableMergeConfirm.open(this.table_merge.table_to)
                            return
                        }
                    } else {
                        this.$notify({ group: "notifications", type: 'error', title: "Fout opgetreden", text: "Oeps er ging iets fout.." }, 2000);
                    }
                }).finally(() => {
                    this.initialized = true
                })
        },

        tableMergeToggle() {
            if (this.table_merge.active == true) {
                this.table_merge = {
                    active: false,
                    table_from: null,
                    table_to: null,
                }
            } else {
                this.table_merge = {
                    active: true,
                    table_from: null,
                    table_to: null,
                }
            }
        },

        isSubtableActive(table) {
            if (table?.attributes?.parent_id) {
                return false
            }

            if (!table?.attributes?.total) {
                return false
            }

            return Boolean(this.area?.attributes?.subtable_flag ?? false)
        },
    },
	computed: {
		clerk() {
			return this.$store.getters['transaction/clerk'];
        },
    }
}
</script>
